
import {
    defineComponent,
    reactive, ref,
    nextTick,
    PropType,
    computed
} from 'vue';
import itemTitle from '@/components/view/common/dashboard/ItemTitle.vue';
import propertyBreadCrumb from '@/components/view/pm/bread-crumb';
import router, { propertyBaseRouter, propertySubRouter } from '@/router';
import screenSaverApi, { GetScreenSaverDeviceList, GetScreenSaverImgList, GetScreenSaverList } from '@/api/pm/screen-saver';
import chooseImg from '@/components/common/choose-image';
import Notice from '@/util/notice.base';

export default defineComponent({
    components: {
        propertyBreadCrumb,
        chooseImg,
        itemTitle
    },
    props: {
        type: {
            type: String as PropType< OperateType >,
            default: 'add'
        }
    },
    setup(props) {
        const breadCrumbHeaders = [{
            label: WordList.OrderDevice,
            path: `/${propertyBaseRouter}/${propertySubRouter.device}`
        }, {
            label: WordList.SetScreenSaver,
            path: `/${propertyBaseRouter}/${propertySubRouter.screenSaver}`
        }, {
            label: props.type === 'edit' ? WordList.TabelConfigInHtmlModify : WordList.ProperAllTextNew
        }];

        // 屏保模式选项
        const modeOptions = [{
            label: WordList.Blank,
            value: 1
        }, {
            label: WordList.Image,
            value: 2
        }];

        // 表单规则
        const rules = {
            DeviceUUID: [
                { required: true, message: WordList.RuleCommonLabelEmpty2.format(WordList.OrderDevice), trigger: 'blur' }
            ]
        };

        const formRef = ref();
        // 表单数据
        const formData = reactive({
            DeviceUUID: '',
            ImgUUID: '',
            UUID: '',
            Mode: 2
        });
        // 编辑时保存详情
        const editImgUUID = ref('');
        const editLocation = ref('');
        if (props.type === 'edit') {
            // 编辑时不对设备必填判断
            rules.DeviceUUID = [];

            const info = JSON.parse(localStorage.getItem('screenSaver')!) as GetScreenSaverList['detail'][0];
            editImgUUID.value = info.ImgUUID;
            editLocation.value = info.Location;
            formData.Mode = Number(info.Mode);
            formData.UUID = info.UUID;
        }

        // 下拉框设备选项
        const deviceList = reactive<GetScreenSaverDeviceList>({
            total: 0,
            rows: [],
            detail: []
        });
        // 获取可创建屏保的设备列表
        function getDeviceApi() {
            screenSaverApi.getScreenSaverDeviceList({}, (res) => {
                deviceList.detail = res.detail;
                deviceList.rows = res.rows;
                deviceList.total = res.total;
                if (res.total > 0) {
                    formData.DeviceUUID = deviceList.rows[0].UUID;
                }
            });
        }
        getDeviceApi();
        console.log(12323);
        console.log(12323);

        // 获取当前官方预设图片和上次上传的自定义图片
        const saveImgList = reactive<GetScreenSaverImgList>({
            system: [],
            project: []
        });
        // 请求后台保存的预设和自定义图片
        function getSaverImgListApi() {
            screenSaverApi.getScreenSaverImgList({}, (res) => {
                saveImgList.project = res.project;
                saveImgList.system = res.system;
                const editImgs = editImgUUID.value.split(',');
                // 渲染已经勾选的图片
                res.project.forEach((item) => {
                    if (editImgs.includes(item.UUID)) {
                        saveCustomizedImg.value.push(item.UUID);
                    }
                });
                res.system.forEach((item) => {
                    if (editImgs.includes(item.UUID)) {
                        saveOfficialImg.value.push(item.UUID);
                    }
                });
            });
        }
        getSaverImgListApi();

        // 保存勾选的官方和自定义图片
        const saveOfficialImg = ref<string[]>([]);
        const saveCustomizedImg = ref<string[]>([]);
        // 计算当前勾选的数量是否达到5张
        const upperLimit = computed(() => saveOfficialImg.value.length + saveCustomizedImg.value.length >= 5);
        // 点击图片同时也勾选勾选框
        function saveImg(originalItems: string[], uuid: string) {
            // 达到上限时，图片不可点击
            if (upperLimit.value && !originalItems.includes(uuid)) {
                return false;
            }
            const itemIndex = originalItems.indexOf(uuid);
            if (itemIndex === -1) {
                originalItems.push(uuid);
            } else {
                originalItems.splice(itemIndex, 1);
            }
            return true;
        }

        // 删除自定义图片
        function removeCustomizedImg(event: PointerEvent, index: number, uuid: string) {
            event.stopPropagation();
            screenSaverApi.delScreenSaverImg({
                UUID: uuid
            }, () => {
                saveImgList.project.splice(index, 1);
                // 如果已勾选需要移除选项
                const itemIndex = saveCustomizedImg.value.indexOf(uuid);
                if (itemIndex !== -1) {
                    saveCustomizedImg.value.splice(itemIndex, 1);
                }
            });
        }

        // 固定保留一个添加的界面
        const addCusImg = ref();
        // 上传自定义图片，获取返回的路径和uuid
        function selectCustomImg(file: File) {
            const uploadData = new FormData();
            uploadData.append('File', file);
            screenSaverApi.uploadScreenSaverImg(uploadData, (res: {
                data: {
                    UUID: string;
                    Path: string;
                };
            }) => {
                saveImgList.project.push({
                    UUID: res.data.UUID,
                    Path: res.data.Path
                });
            });
            // 手动点击删除
            nextTick(() => {
                addCusImg.value.remove();
            });
        }

        // 添加、编辑接口
        const submitApi = {
            add: screenSaverApi.addScreenSaver,
            edit: screenSaverApi.editScreenSaver
        };

        // 提交表单
        function submit() {
            formRef.value.validate((valid: boolean) => {
                // 勾选图片小于两张时，弹窗提示
                if (saveOfficialImg.value.length + saveCustomizedImg.value.length < 2) {
                    Notice.messageBox(
                        'alert',
                        WordList.ScreensaverLengthLimit,
                        WordList.TabelDeleteTitle,
                        undefined,
                        WordList.TabelFootSure
                    )(() => false);
                    return false;
                }
                if (valid) {
                    formData.ImgUUID = [...saveOfficialImg.value, ...saveCustomizedImg.value].join(',');
                    submitApi[props.type]({ ...formData }, () => {
                        router.push(`/${propertyBaseRouter}/${propertySubRouter.screenSaver}`);
                    });
                }
                return true;
            });
        }
        return {
            breadCrumbHeaders,
            deviceList,
            formData,
            saveImgList,
            saveOfficialImg,
            saveCustomizedImg,
            removeCustomizedImg,
            addCusImg,
            selectCustomImg,
            modeOptions,
            rules,
            submit,
            formRef,
            editLocation,
            upperLimit,
            saveImg
        };
    }
});
